<template>
    <div class="classSearchWrap">
      <!-- <div class="classWrap">
        <LevelsMenu  @change="queryProduct"></LevelsMenu>
        </div> -->
        <div class="adress">
          <div class="content-top">
            <ul>

              <li>

                <div>地&nbsp;&nbsp;区:</div>

                <div>
                    <!-- <div class="allBtn">全部</div> -->
                    <div  v-for="(item4,index) in AdressClassList" :key="index">
                      <span @click="selectClass(item4.adcode,index,item4.NAME)" :class="activeClass == index ? 'active' : ''" :key="index">
                      {{item4.NAME}}
                      </span>
                    </div>
                </div>

              </li>
            </ul>
          </div>
        </div>

      <!--以下是搜索产品结构  -->

        <div class="section" v-if="isProduct">
            <div class="section-left">
                <div class="recommend-list-wrap" v-for="(item1,i1) in ShopListContent" :key="i1">
                        <li class="recommend-list" :class="[ !item1.productList.length=='0' ? 'recommend-list' : 'recommend-list2' ]" >
                        <div class="shopAd" @click="goshop(item1.storeId)">
                            <div class="shopAd-content">
                                <div class="shopAd-content-left">
                                    <img :src="item1.logoUrl | imagePath" alt="" @click="goshop(item1.storeId)">
                                </div>
                                <div class="shopAd-content-right">
                                    <div class="opens">
                                    <span @click="goshop(item1.storeId)">{{item1.storeName}}</span>
                                    <!-- <span v-if="item1.isPay">v1</span> -->
                                    <!-- <span v-if="item1.qiyePassport">v2</span> -->
                                    <span v-if="item1.sellerRank==3">v3</span>
                                    <span v-if="item1.sellerRank==2">v2</span>
                                    <span v-if="item1.sellerRank==1">v1</span>
                                    <span>
                                        <img src="../../assets/icon/ying.png" alt="">
                                    </span>
                                    <span>
                                        <img src="../../assets/icon/renzheng.png" alt="" v-if="item1.pay">
                                    </span>
                                    <span>
                                        <img src="../../assets/icon/location.png" alt="">
                                    </span>
                                    <span>
                                        {{item1.address}}
                                    </span>
                                    <span>
                                        <img src="../../assets/icon/shutiao.png" alt="">
                                    </span>
                                    <!-- <span>
                                        在售产品：{{item1.storeProductCount}}
                                    </span> -->
                                    <span>
                                        成交量：{{item1.salesVolume}}
                                    </span>
                                    <!-- <span>
                                        服务案例：{{item1.storeProductCaseCount}}
                                    </span> -->
                                    <button type="button" class="shopAd-btn" @click="goshop(item1.storeId)">进入店铺</button>
                                    </div>
                                    <div class="server">
                                        <div class="server-title">
                                        擅长服务：
                                        </div>
                                        <div class="server-list">
                                        <span v-for="(item2,i2) in item1.serverList" :key="i2">
                                            {{item2}}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div class="productBtnWrap">
                                    <button type="button" class="shopAd-btn" @click.stop="dialogVisible = true;phone(item1.storeId)">联系商家</button>
                                    <button type="button" class="shopAd-btn" @click.stop="dialogVisible2 = true;oneKeyShow(item1.storeName,item1.storeId)">一键支付</button>
                                </div>
                            <!-- <button type="button" class="shopAd-btn" @click="goshop(item1.storeId)">进入店铺</button> -->
                        </div>

                        <div class="productWrap">
                            <div class="productWrap-top">
                                <div class="productWrap-top-content" v-if="item1.productList.length!=0">
                                    <!-- {{item1.productList}} -->
                                    <dl v-for="(item3,i3) in item1.productList" :key="i3">
                                        <dt>
                                        <img :src="item3.productPic | imagePath" alt="" @click="godetail(item3.storeId,item3.productId)">
                                        </dt>
                                        <dd>
                                        <p @click="godetail(item3.storeId,item3.productId)" v-html="item3.isStyle"></p>
                                        <p>
                                            <!-- <span>参考价：</span> -->
                                            <span>￥{{item3.price}} </span>
                                            <span>起</span>
                                        </p>
                                        <div @click="dialogVisible1 = true;productShow(item3.productPic,item3.name,item3.price,item3.productId,item3.storeId)" >快速下单</div>
                                        </dd>
                                    </dl>


                                </div>
                                <div v-if="item1.productList.length==0" class="oneKeywrap">
                                    商家暂未选定产品出售，您可以直接联系商家沟通业务并点击
                                    “
                                    <span class="oneKey">一键支付</span>
                                    ”
                                    快速生成订单 ~

                                </div>
                            </div>

                            <div class="otherwrap" v-if="item1.productList.length!=0">
                                <!-- <span>查看本店其他更多热售产品  >></span> -->

                            </div>
                        </div>
                        </li>
                 </div>
            </div>
             <div class="section-right">
                <div>推荐优质商家</div>
                <div class="merchant-list" >
                  <dl v-for="(item5,i5) in MerchantList" :key="i5" @click="goshop(item5.id)">
                    <dt>
                      <img :src="item5.logoUrl | imagePath"  @click="goshop(item5.id)">
                    </dt>
                    <dd>
                      <div>
                        <span @click="goshop(item5.id)">{{item5.storeName}}</span>
                         <span v-if="item5.sellerRank==3">v3</span>
                        <span v-if="item5.sellerRank==2">v2</span>
                        <span v-if="item5.sellerRank==1">v1</span>
                      </div>
                      <div>
                        <img src="../../assets/icon/location.png" alt="">
                        <span >{{item5.address}}</span>
                      </div>
                    </dd>
                  </dl>
                </div>
             </div>
      </div>



      <!--以下是搜索店铺结构  -->
       <div class="section1" v-if="isShop">
                <div class="recommend-list-wrap" v-for="(item1,i1) in ShopListContent" :key="i1">
                        <li class="recommend-list" :class="[ !item1.productList.length=='0' ? 'recommend-list' : 'recommend-list2' ]" >
                       <div class="shopAd" @click="goshop(item1.storeId)">
                        <div class="shopAd-content">
                            <div class="shopAd-content-left">

                              <img :src="item1.logoUrl | imagePath" alt="" @click="goshop(item1.storeId)">
                            </div>
                            <div class="shopAd-content-right">
                              <div class="opens">
                                <span @click="goshop(item1.storeId)">{{item1.storeName}}</span>
                               <span v-if="item1.sellerRank==3">v3</span>
                                <span v-if="item1.sellerRank==2">v2</span>
                                <span v-if="item1.sellerRank==1">v1</span>
                                <span>
                                  <img src="../../assets/icon/ying.png" alt="">
                                </span>
                                <span>
                                  <img src="../../assets/icon/renzheng.png" alt="" v-if="item1.pay">
                                </span>
                                <span>
                                  <img src="../../assets/icon/location.png" alt="">
                                </span>
                                <span>
                                  {{item1.address}}
                                </span>
                                <span>
                                  <img src="../../assets/icon/shutiao.png" alt="">
                                </span>
                                <span>
                                  在售产品：{{item1.storeProductCount}}
                                </span>
                                <span>
                                  成交量：{{item1.salesVolume}}
                                </span>
                                <span>
                                  服务案例：{{item1.storeProductCaseCount}}
                                </span>
                              </div>
                              <div class="server">
                                  <div class="server-title">
                                    擅长服务：
                                  </div>
                                  <div class="server-list">
                                    <span v-for="(item2,i2) in item1.serverList" :key="i2">
                                      {{item2}}
                                    </span>
                                  </div>
                              </div>
                            </div>
                        </div>
                        <button type="button" class="shopAd-btn" @click="goshop(item1.storeId)">进入店铺</button>
                    </div>

                        <div class="productWrap">
                            <div class="productWrap-top">
                                <div class="productWrap-top-content" v-if="item1.productList.length!=0">
                                    <!-- {{item1.productList}} -->
                                    <dl v-for="(item3,i3) in item1.productList" :key="i3">
                                        <dt>
                                        <img :src="item3.productPic | imagePath" alt="" @click="godetail(item3.storeId,item3.productId)">
                                        </dt>
                                        <dd>
                                        <p @click="godetail(item3.storeId,item3.productId)">{{item3.name}}</p>
                                        <p>
                                            <!-- <span>参考价：</span> -->
                                            <span>￥{{item3.price}} </span>
                                            <span>起</span>
                                        </p>
                                        <div @click="dialogVisible1 = true;productShow(item3.productPic,item3.name,item3.price,item3.productId,item3.storeId)" >快速下单</div>
                                        </dd>
                                    </dl>


                                </div>
                                <div v-if="item1.productList.length==0" class="oneKeywrap">
                                    商家暂未选定产品出售，您可以直接联系商家沟通业务并点击
                                    “
                                    <span class="oneKey">一键支付</span>
                                    ”
                                    快速生成订单 ~

                                </div>
                                <div class="productBtnWrap">
                                  <button type="button" class="shopAd-btn" @click="dialogVisible = true;phone(item1.storeId)">联系商家</button>
                                    <button type="button" class="shopAd-btn" @click="dialogVisible2 = true; oneKeyShow(item1.storeName,item1.storeId)">一键支付</button>
                                </div>
                            </div>

                            <div class="otherwrap" v-if="item1.productList.length!=0">
                                <span>查看本店其他更多热售产品  >></span>

                            </div>
                        </div>
                        </li>
                 </div>

        </div>



      <!-- 以下是公用弹窗结构 -->
        <div class="quickOrder">
              <el-dialog title="" :visible.sync="dialogVisible">
                <div class="popTop">
                  <p class="storeName">{{store.storeName}}</p>
                <div class="storeMobile">
                  <div>
                    <span>
                      <img src="../../assets/image/phone.png" alt="" align="middle">
                    </span>
                    <span>{{store.mobile}}</span>
                  </div>
                  <div class="streFooter">
                    注：为了您的交易安全，请走孔雀有礼线上交易如遇可疑商家，可及时向平台投诉举报！
                  </div>
                </div>
                </div>
              </el-dialog>
            </div>

            <div class="quickOrder1">
              <el-dialog title="" :visible.sync="dialogVisible1">
                  <div class="popWrap">
                    <div class="titles">
                      <p>快速下单</p>
                      <p>您</p>
                      <p>已选定产品</p>
                      <p>且已经与商家沟通好价格，直接付款</p>
                      <p >可快速生成订单</p>
                    </div>
                    <div class="productPop">
                        <p>我需要：</p>
                        <div class="product-img">
                          <img :src="productObj.img | imagePath" alt="">
                        </div>
                        <div>
                          <p>{{productObj.name}}</p>
                          <div>
                            <span>参考价：</span>
                            <span>￥{{productObj.price}}</span>&nbsp;
                            <span>起</span>

                          </div>
                        </div>
                    </div>

                    <el-form ref="form" :model="form">
                      <el-form-item label=" 订单金额：" prop="consignee">
                        <el-input v-model="form.price" placeholder="请输入与商家已沟通好的价格（元）" @input="oninput"></el-input>
                      </el-form-item>
                      <el-form-item label="订单备注：" prop="mobile">
                        <el-input v-model="form.info" placeholder="输入您对本订单的制作/配送/开票等要求说明（选填）" ></el-input>
                      </el-form-item>


                      <el-form-item class="save">
                        <el-button class="details" type="primary" @click="onSubmit">立即提交</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
              </el-dialog>
            </div>

            <div class="quickOrder2">
              <el-dialog title="" :visible.sync="dialogVisible2">
                <div class="popWrap">
                  <div class="titles">
                    <p>一键下单</p>
                    <p>您已经与商家沟通好价格，直接付款</p>
                    <p>可快速生成订单</p>
                  </div>

                  <el-form ref="form" :model="form">
                    <el-form-item label=" 我需要：" prop="consignee">
                      <!-- <el-input v-model="form.requirementDesc" placeholder=""></el-input> -->
                      <el-input
                          type="textarea"
                          placeholder="简要说明您需要做的业务（包括物料、数量、工艺、服务要求等）"
                          v-model="form.info">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="订单金额：" prop="mobile">
                      <el-input v-model="form.price" placeholder="请输入与商家已沟通好的价格（元）" maxlength="11" @input="oninput"></el-input>
                    </el-form-item>

                    <div class="protocol">
                        <el-checkbox v-model="checked" @change="checkedAll">
                          <span>我已阅读并同意&nbsp;</span>
                        </el-checkbox>
                        <span class="protocol-word">《孔雀有礼使用协议》</span>
                    </div>

                    <el-form-item class="save" v-if="isDisable">
                      <el-button class="details" type="primary"  disabled >立即提交</el-button>
                    </el-form-item>
                    <el-form-item class="save"  v-if="isSave" >
                      <el-button class="details" type="primary" @click="onSubmit1">立即提交</el-button>
                    </el-form-item>
                  </el-form>

                </div>
            </el-dialog>
            </div>



          <!-- 以下缺省页 -->
          <div class="shop-default" v-show="isShopPage">
            <div>
              很抱歉
              <span>在{{city}}</span>
              未查询到与
              <span>“{{this.param.name}}"</span>
              相关的店铺，您可以换个关键词试试
            </div>
<!--            <div class="needWrap">-->
<!--              您也可以点击左上角-->
<!--              <span>切换城市,</span>-->
<!--              <span>或发布一条&nbsp;</span>-->
<!--              <span @click="publish">广告需求>></span>-->
<!--              <span>，让小孔雀精准匹配商家为您服务~</span>-->
<!--            </div>-->
          </div>

          <div class="shop-default" v-show="isProductPage">
            <div>
              很抱歉
              <span>在{{city}}</span>
              未查询到与
              <span>“{{this.param.name}}"</span>
              相关的产品，您可以换个关键词试试
            </div>
<!--            <div class="needWrap">-->
<!--              您也可以点击左上角-->
<!--              <span>切换城市,</span>-->
<!--              <span>或发布一条&nbsp;</span>-->
<!--              <span @click="publish">广告需求>></span>-->
<!--              <span>，让小孔雀精准匹配商家为您服务~</span>-->
<!--            </div>-->
          </div>
           <!-- <div class="product-default" v-show="isProductPage">
            <div>
              <span>当前城市</span>
              当前城市未查询到与“{{this.param.name}}"相关的产品，您可以换个关键词试试
            </div>
            <div class="needWrap">
              <span>您也可以&nbsp;</span>
              <span @click="publish">发布一条广告需求</span>
              <span>，让小孔雀精准匹配商家为您服务~</span>
            </div>
          </div> -->
          <div class="pagtion">
              <el-pagination
              @size-change="sizeChangePage"
              @current-change="currentChangePage"
              :current-page="page"
              :page-sizes="[10, 20, 30, 40]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalSize"
              >
              </el-pagination>
          </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LevelsMenu from '@/components/common/levelsMenu'
import { setTimeout } from 'timers';
export default {
    components: {  LevelsMenu},
    name:"classSearch",
    data(){
        return{

      totalSize:0, //总条数

      isSave:true, //保存可用
      isDisable:false, //保存可用
      checked: true,
      //一键下单付款信息
      // form1: {
      //   "info":"", //买家需求
      //   "price": '',//价格
      // },

      //快速下单信息,一键支付信息
      form: {
        info: '', //备注信息
        price:"",
      },
      page: 0, //当前第几页

      activeName: 'first', //tab默认为第一个
      sum:"会场庆典",
      sum2:"会场庆典",
      sum3:"会场庆典",
      recommendList:[], //孔雀推荐一级分类
      ShopListContent:[], //分类搜索店铺列表内容
      param:{ //点击分类传递的参数
        // lat:JSON.parse(localStorage.getItem("lng")),
        // lon:JSON.parse(localStorage.getItem("lat")),

        // adcode:JSON.parse(localStorage.getItem("adcode")), //搜索优化后不传此参数
        lonStr:JSON.parse(sessionStorage.getItem("lng")), //经度
        latStr:JSON.parse(sessionStorage.getItem("lat")), //纬度
        flag:"0",
        name:this.$route.query.val, //用户搜索到的关键字
         state:"0", //默认产品搜索标识
         sqlState:"", //默认升序成交量
         page:"0",
         rows:"10",
         cateId:this.$route.query.cateId,
          cateState:this.$route.query.cateState
        // cateId:this.$route.params.id, //任意分类的id,默认为点击进来分类的id
      },
      dialogVisible: false, //电话弹窗显示与否
      store: {}, //保存弹窗信息的对象
      dialogVisible: false, //联系商家显示与否
      dialogVisible1: false, //快速下单弹窗显示与否
      dialogVisible2: false, //一键支付弹窗显示与否
      productObj:{ //点击快速下单展示数据
        img:"",
        name:"",
        price:"",
        id:"",
        storeId:""
      },
      city:JSON.parse(localStorage.getItem("city")), //当前城市
      cityName:JSON.parse(localStorage.getItem("city")), //当前城市
      AdressClassList:[], //保存区的数组
      activeClass: -1, //区高亮
      MerchantList:[], //优质商家
      isProduct:false,
      isShop:false,
      isShopPage:false, //店铺缺省页
      isProductPage:false, //产品缺省页

        }
    },
    watch: {
     $route(to){
      //  this.param.name=this.$route.query.val;
      //  this.param.cateId=this.$route.query.cateId;
      //  this.param.cateState=this.$route.query.cateState;
        this.getShopListContent(); //在搜索页继续搜索获取商铺产品列表
        // alert(to.query.val)
        if (to.name=="searchProduct") {
          this.param.name=to.query.val
          this.getShopListContent();
        }
    }
  },
      mounted() {
    this.storeList();
    this.getShopList(); //获取商铺产品列表

    this.getAdressClass(); //获取区数据
    this.getMerchant(this.cityName); //推荐商家
    // console.log(666,this.$route.query.cateId)
    // this.param.name=this.$route.query.val;
    // this.param.cateId=this.$route.query.cateId;
    // this.param.cateState=this.$route.query.cateState;
    this.getShopListContent(); //获取商铺产品列表
    if (JSON.parse(sessionStorage.getItem("lng1"))!=null) {
      this.param.lonStr=JSON.parse(sessionStorage.getItem("lng1")); //经度
      this.param.latStr=JSON.parse(sessionStorage.getItem("lat1")); //纬度
    }
  },
  created(){

    // this.activeName = "推荐";
  },
   computed: {
    ...mapGetters(['user', 'isLogin', 'keywords'])
  },
  methods: {
    //以下分页
    //选择每页显示条数触发
     sizeChangePage(val) {
        let self = this;
        self.param.rows = val;
        // console.log(`每页 ${val} 条`);
        self.getShopListContent(); //请求接口
     },
    //选择第几页触发
     currentChangePage(val) {
    // console.log(`当前页: ${val}`);
        let self = this;
        self.param.page = val;
        self.getShopListContent();
     },
    oninput(e) {
        // 通过正则过滤小数点后两位
    this.form.price = (this.form.price.match(/^\d*(\.?\d{0,2})/g)[0]) || null
    },
    publish() { //去发布需求
      this.$router.push({ path: '/requirement' })
    },
    godetail(storeId,productId) { //去产品详情

      this.$router.push({ path: '/productDetail', query: { sid: storeId, pid: productId } })
    },



    selectClass(adcode,index,NAME){ //地区分类选择
      if (index==0) { //点击全部地区
      this.param.flag="0";
      // this.param.acode=JSON.parse(localStorage.getItem("city"));
      this.param.adcode=JSON.parse(localStorage.getItem("city"));
      this.getMerchant(this.cityName=JSON.parse(localStorage.getItem("city")));
    }
    else{ //点击其他地区分类

      this.param.flag="1";
      // this.param.adcode=adcode;
      this.param.adcode=adcode; //优质商家参数
      setTimeout(()=>{
        this.city=JSON.parse(localStorage.getItem("city"))+NAME;
      },100)

      this.getMerchant(this.cityName=this.param.adcode); //优质商家切换); //优质商家切换
    }

      this.$http.post('/store/product/name',this.param).then(res=>{
        if(res.data.data.list.length==0){
          this.isShopPage=true;
          this.ShopListContent=res.data.data.list;
        }else{
          this.isShopPage=false;
          this.ShopListContent=res.data.data.list;
        }
      })
      // this.getShopListContent();

      this.activeClass = index;
    },




    getMerchant(){ //获取优质推荐商家
         this.$http.get('store/highQuality?name='+this.cityName).then(res => {
            this.MerchantList = res.data.data;
          })
    },
    getAdressClass(){ //地区分类数据获取
      this.$http.get('/area/getAreaByCityName?name='+this.cityName).then(res => {
         let obj={
          NAME: "全部",
          adcode: this.param.adcode,
          citycode: "",
          latitude: "",
          longitud: "",
        }
        this.AdressClassList = res.data.data;
        this.AdressClassList.unshift(obj);
      })
    },
    //以下一键支付下单
    checkedAll(e){ //选中协议
      if (!e) {
        this.isDisable=true;
        this.isSave=false;
      }
      if(e){

        this.isDisable=false;
        this.isSave=true;
      }
    },

    oneKeyShow(storeName,storeId){ //点击一键支付时，保存店铺信息
     if (this.isLogin=="0") {
         this.$message.error('请先登录!');
         this.dialogVisible2=false;
         this.$router.push("/login");
       }
      this.fastList={
        "storeName":storeName,
        "storeId":storeId,
      }
    },
    onSubmit1(){ //一键支付
      if (this.form.price=="") {
       this.$message.error('请输入订单金额!');
       return;
     }
     if (!this.$check.checkMoney(this.form.price)) {
              this.$message.error("请输入订单金额!");
                return;
        }

          this.fastList.info=this.form.info; //需求信息
          this.fastList.price=this.form.price;//一键支付输入的价格
          this.fastList.fastFlag="2";
          this.fastList.img="upload/b060a4a7810743fa8ea8cdc56d124138.png";
          this.fastList.name="线下业务单";
          this.fastList.sn="";
          localStorage.setItem("fastList",JSON.stringify(this.fastList));
        this.$router.push({
            name:"order",
        })
    },






     productShow(img,name,price,id,storeId){
       if (this.isLogin=="0") {
         this.$message.error('请先登录!');
         this.dialogVisible1=false;
         this.$router.push("/login");
       }
       this.productObj.img=img;
       this.productObj.name=name;
       this.productObj.price=price;
       this.productObj.id=id;
        this.productObj.storeId=storeId;
     },
      onSubmit(){ //快速下单提交
      if (this.form.price=="") {
       this.$message.error('请输入订单金额!');
       return;
     }
     if (!this.$check.checkMoney(this.form.price)) {
              this.$message.error("请输入订单金额!");
                return;
        }


        let fastList ={
          name:this.productObj.name,
          storeId:this.productObj.storeId,
           id:this.productObj.id,
           img:this.productObj.img,
            info:this.form.info,
            price:this.form.price,
           fastFlag:"0"
          }
          localStorage.setItem("fastList",JSON.stringify(fastList));
          this.$router.push({
              name:"order",
          })
     },
    phone(storeId){ //联系商家
      this.$http.get('store/info', { params: { storeId: storeId } }).then(res => {
        this.store = res.data.data;
      })
    },
    goshop(storeId){ //首页列表进店铺
      this.$router.push({
        name:"shopPage",
        params:{
          id:storeId
        }
      })
    },

    //，搜索产品传递state=0，搜索店铺传递state=1,模糊搜索默认传递state=0
    getShopListContent(){ //搜索框搜索相关事件

    // 点击搜索店铺搜索
    if (this.$route.query.shopFlag==1) {
      // console.log(JSON.parse(localStorage.getItem("city")),79980)
      // this.param.adcode=JSON.parse(localStorage.getItem("city"));
      this.param.state="1";
      this.param.flag="0";
      this.isProduct=false;
      this.isShop=true;
      this.$http.post('/store/product/name',this.param).then(res=>{
        //如果搜索店铺为空
        if(res.data.data.list.length==0){
          this.isShopPage=true;
        }else{
          this.isShopPage=false;
          this.ShopListContent=res.data.data.list;
        }
      })
      return;
    }

    // 点击搜索按钮或者点击下拉搜索产品或者enter触发搜索
    // this.param.adcode=JSON.parse(localStorage.getItem("city"));
      this.$http.post('/store/product/name',this.param).then(res=>{


        //优先搜索产品
        if(res.data.data.flag==0){ //优先搜索产品
          this.isProduct=true;
          this.isShop=false;
          this.ShopListContent=res.data.data.list;
          this.totalSize=res.data.data.totalSize; //总条数
        }

        // 其次搜索店铺
        if(res.data.data.flag==1){
           this.isProduct=false;
            this.isShop=true;
            this.ShopListContent=res.data.data.list;
        }

        //如果什么都搜索不到,展示产品缺省页
          if(res.data.data.list.length==0){
            this.isProductPage=true;
            return;
          }else{
            this.isProductPage=false;
          }

      })
    },

    getShopList(){ //
      this.$http.get('pic/adv/config/new/list?code=pc_sy_fltj').then(res=>{
        this.recommendList=res.data.data;
      })
    },

    storeList() {

    },


    },
  }
    // methods: {


</script>

<style lang="scss" scope="">
*{
    margin:0;
    padding:0;
}
.classSearchWrap{
  background: #f5f5f5;
  padding-bottom: 66px;

}
.titles{
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid  #eee;
      margin-bottom: 20px;
      height: 48px;
      :nth-child(1){
        margin-right: 20px;
        line-height: 20px;
        font-size: 20px;
        color: #333;
        font-weight: 900;
      }
      :nth-child(2){
        font-size: 16px;
        color:#333;
      }
    }
li{
    list-style: none;
}
em{
  font-style: normal;
}
.classWrap{
  // margin-bottom: 20px;
}
    .adress{
        width: 1200px;
        margin:0 auto;
        background: #fff;
          .content-top{
          margin-top: 10px;
          margin-bottom: 10px;


        ul{
          border-bottom: 1px solid #eee;
           li{
              width: 100%;
              list-style: none;

              font-size: 14px;
              border-bottom: 1px dashed #e5e5e5;
              color: #333;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              >div{
                line-height:40px;
                max-width:1050px;
                display: flex;
                flex-wrap: wrap;
                span{
                  margin-right: 30px;
                  cursor: pointer;
                  &:hover{
                    color: #d82229;
                  }
                }
                .active{
                  color: #d82229;
                }
              }
               >:first-child{
                text-align: left;
                width: 77px;
                margin-right: 5px;
              }
            }
            li:last-child{
              border: none;
            }
        }
        .sort{
               width: 100%;
              // min-height: 51px;
              line-height: 45px;
              font-size: 16px;
              color: #333;
              border-left: none;
              border-right: none;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              em{
                margin-right: 30px;
              }
              span{
                margin-right: 30px;
              }
            }

          }
    }








     .section{
         width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
        .section-left{
          width: 950px;

          .el-tabs__item.is-active {
            color: #d82229;
          }

          .el-tabs__active-bar {
            height: 4px;
            background-color: #d82229;
          }

          .el-tabs__nav > div {
            &:hover {
              color: #d82229 !important;
            }
          }

          .el-tabs__item {
            font-size: 18px;
            color: #333;


          }

          .el-tabs__nav {
            height: 60px;
            line-height: 60px;
            margin-top: 5px;
          }

          .el-tabs__content {
            display: block;
          }
          .is-top{
            margin-bottom: 0px;
          }


          .recommend-list-wrap{
            margin-top: 15px;
            .recommend-list{ //大列表
               padding: 0 20px;
               height: 320px;
               background: #fff;
               margin-bottom: 20px;
               .shopAd{ //广告公司信息
                  height: 110px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  cursor: pointer;
                  .shopAd-content{ //左侧主体
                      width: 720px;
                      height: 70px;
                      display: flex;
                      justify-content: flex-start;
                      .shopAd-content-left{
                        margin-right: 15px;
                        img{
                          display: block;
                          width:70px;
                          height: 70px;
                          margin-right: 10px;
                        }
                      }
                      .shopAd-content-right{

                        .opens{
                          height: 30px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          span{
                            margin-right: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          }
                          :nth-child(1){
                            font-size: 18px;
                            color: #333;
                            font-weight: 900;
                          }
                          :nth-child(2){
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            line-height: 20px;
                            font-size: 14px;
                            color: #fff;
                            background: #ffac00;
                            border-radius: 2px;
                            // padding:2px;
                          }
                          :nth-child(5){
                            margin-right:6px;
                            font-size: 14px;
                            color: #666;
                            margin-right: 0;
                          }
                          :nth-child(6){
                            // background: red;
                            font-size: 14px;
                            margin: 0 10px;
                            color: #666;
                          }
                          :nth-child(8){
                             max-width: 180px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          }
                          :nth-child(8),:nth-child(9),:nth-child(10){
                            font-size: 14px;
                            color: #666;
                            margin-right: 12px;
                          }
                          .shopAd-btn{ //右侧进入店铺按钮
                            outline: none;
                            width: 68px;
                            height: 24px;
                            font-size: 14px;
                            color:#ffa219;
                            border-radius: 3px;
                            background: none;
                            border: 1px solid #ffa219;
                            cursor: pointer;
                            margin-right: 70px;
                          }
                        }
                        .server{
                          margin-top:10px;
                          height: 30px;
                          // background: orange;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          .server-title{
                              font-size: 14px;
                              color: #666;
                              margin-right: 12px;
                          }
                          .server-list{
                              font-size: 12px;
                              color:#999;
                              span{
                                padding: 5px 6px;
                                margin-right: 14px;
                                border: 1px solid #e5e5e5;
                                border-radius: 2px;
                              }
                              :last-child{
                                margin-right: 0;
                              }
                          }
                        }

                      }

                  }
                  .productBtnWrap{
                  width: 150px;
                  height: 110px;
                  padding-left: 40px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  margin-right: 35px;
                  padding: 15px 0 15px 40px;
                  .shopAd-btn{ //右侧进入店铺按钮
                    margin-left: auto;
                    outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 16px;
                    color:#fff;
                    border-radius: 3px;
                    background: none;
                    cursor: pointer;
                    margin-right: 70px;
                    border:1px solid #d82229;
                    color: #d82229;
                    // border: none;
                  }
                  :nth-child(2){
                    background: #d82229;
                    color: #fff;
                  }
                }

              }
              .otherwrap{
                line-height: 50px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                color: #999;
                span{
                  &:hover{
                  color: #ff0a51;
                  cursor: pointer;
                }
                }
              }
            }
            .recommend-list2{
              height: 280px;
            }
            >:last-child{
              margin-bottom: 0;
            }

            .productWrap{
              .productWrap-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                .productWrap-top-content{
                  display: flex;
                  dl{
                    width: 288px;
                    height: 140px;
                    display: flex;
                    margin-right: 20px;
                    justify-content: space-between;
                    dt{
                      width: 140px;
                      height: 140px;
                      overflow: hidden;
                      flex-shrink: 0;
                      img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                      }
                    }
                    dd{
                      width: 139px;
                      height: 100px;
                      font-size: 16px;
                      color: #333;
                      flex-shrink: 0;
                      >:nth-child(1){
                        margin-top: 11px;
                        height: 44px;
                        text-align: left;
                        cursor: pointer;
                        &:hover{
                          color: #d82229;
                        }

                      }
                      >:nth-child(1),>:nth-child(2){
                        text-align: left;
                        line-height:22px;
                        color: #333;
                        font-size: 16px;
                        word-break: break-all;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                      >:nth-child(2){
                        line-height: 34px;
                          >:nth-child(1){
                            color: #ff0a50;
                            font-weight: 600;
                          }
                      }
                      >:nth-child(3){
                        width: 110px;
                        height: 34px;
                        outline: none;
                        background: none;
                        border-radius: 3px;
                        border: 1px solid #333;
                        text-align: center;
                        line-height: 34px;
                        color:#333;
                        font-size: 14px;
                        cursor: pointer;
                        margin-top: 3px;
                      }
                    }
                  }
                }
                // .productBtnWrap{
                //   width: 150px;
                //   height: 110px;
                //   padding-left: 40px;
                //   border-left: 1px solid #eeeeee;
                //   display: flex;
                //   flex-direction: column;
                //   justify-content: space-between;
                //   margin-right: 70px;
                //   .shopAd-btn{ //右侧进入店铺按钮
                //     margin-left: auto;
                //     outline: none;
                //     width: 108px;
                //     height: 34px;
                //     font-size: 16px;
                //     color:#fff;
                //     border-radius: 3px;
                //     background: none;
                //     cursor: pointer;
                //     margin-right: 70px;
                //     border:1px solid #d82229;
                //     color: #d82229;
                //     // border: none;
                //   }
                //   :nth-child(2){
                //     background: #d82229;
                //     color: #fff;
                //   }
                // }
              }

              .productWrapBtn{
                outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 16px;
                    color:#ffa219;
                    border-radius: 3px;
                    background: none;
                    border: 1px solid #ffa219;
                    cursor: pointer;
                    margin-right: 70px;
              }
              .oneKeywrap{
                font-size: 18px;
                .oneKey{
                    color: #ffac00;
                    font-size: 18px;
                 }
              }

            }
          }
          >:nth-child(1){
            margin-top: 0;
          }
        }

        .section-right{
            width: 240px;
            height: 100%;
            background: #fff;
            padding: 20px;

            >:first-child{
              line-height:26px;
              font-size: 16px;
              color: #333;
            }
           .merchant-list{

              display: flex;
              justify-content: space-between;
              flex-direction: column;
              // border-bottom: 1px solid #eee;
              cursor: pointer;
              dl{
                height: 93px;
                display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #eee;
                dt{
                  width: 54px;
                  height: 54px;
                  overflow: hidden;
                  margin-right: 15px;
                  img{
                    display: block;
                    width: 100%;
                    height: 100%;
                  }
                }
                dd{
                  // display: f
                  >:nth-child(1){
                    display: flex;
                    align-items: center;
                    line-height: 31px;
                    // &:hover{
                    //   color: #d82229;
                    // }
                    >:nth-child(1){
                      width: 96px;
                      margin-right: 10px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                    >:nth-child(2){
                      width: 20px;
                      height: 20px;
                      background: #ffa219;
                      color: #fff;
                      border-radius: 2px;
                      text-align: center;
                      line-height: 20px;
                    }
                  }

                  >:nth-child(2){
                    color: #666;
                      font-size: 14px;
                      display: flex;
                      align-items: center;
                      >:nth-child(1){
                        margin-right: 6px;
                      }
                  }
                }
              }
              >:last-child{
                  border: none;
              }
            }
        }
    }





 //以下弹窗样式
 .quickOrder{
 .el-dialog{
  width:440px;
  height:220px;
  margin-top:40vh !important;
  .el-dialog__body{
    padding:0;
  }

  .el-dialog__header{
      background: #f5f5f5;
  }
  .popTop{
    height:120px;
    .storeName{
      text-align: center;
      font-size:22px;
      color:#333;
      height:44px;
      line-height:44px;
      background: #f5f5f5;
    }
    .storeMobile{
      height:66px;
      line-height: 66px;
      font-size:28px;
      color:#333;
     background: #f5f5f5;
      >div{
        width:280px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin:0 auto;
        padding:0 10px;
        overflow: hidden;
        color:#333333;
        img{
          display: block;
        }
      }
      .streFooter{
        text-align: center;
        height: 80px;
        font-size: 12px;
        color: #999;
        display: flex;
        justify-content: center;
        line-height: 24px;
      }
    }
  }

}
 }


.quickOrder1{

  .el-button--primary{
   width: 100%;
   height:100%;
   background: #d82229;
   border: none;
 }
   .el-dialog{
     height: 510px !important;
     .titles{
       font-size: 16px;
       :last-child{
         color: #d82229;
       }
     }
     .productPop{
            display: flex;
            height: 150px;
            margin: 20px 0;

            >:nth-child(1){
              width: 80px;
              padding-right: 12px;
              flex-shrink: 0;
              text-align:right;
            }
            >:nth-child(2){
              width: 150px;
              margin-right:20px;
              flex-shrink: 0;
              width: 150px;
              height: 150px;
              img{
                display: block;
                width: 100%;
                height: 100%;
              }
            }
            >:nth-child(3){
              width: 390px;
              p{

                font-size: 16px;
                line-height: 40px;
                text-align: left;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              >div{
                line-height: 46px;
                text-align: left;

                :nth-child(1){
                  font-size: 14px;
                }
                 :nth-child(2){
                  font-size: 16px;
                  color:#d82229;
                }
                :nth-child(3){
                  font-size: 14px;
                }
              }
            }

          }
   }
     .el-dialog .el-dialog__body .el-form-item .el-form-item__label{
      width: 80px !important;
      flex-shrink: 0;
      padding-right:10px;
      // padding: 0;
  }
 }

.quickOrder2{
   .el-form-item__content{
     width:100%;
   }
   .titles{
       font-size: 16px;
       :last-child{
         color: #d82229;
       }
     }
   .el-textarea{
     width:100%;
     height:180px;
   }
   .el-textarea__inner{
     width: 100%;
     height:180px;
     resize:none;
   }
   .el-dialog{
     height:auto;
     padding-bottom:40px;
   }
   .el-button--primary{
    width: 100%;
    height:100%;
    background: #d82229;
    border: none;
  }
 }




.el-dialog{
  width: 720px;
  .el-dialog__body{
    padding: 0 50px;
    .titles{
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid  #eee;
      margin-bottom: 20px;
      height: 48px;
      :nth-child(1){
        margin-right: 20px;
        line-height: 20px;
        font-size: 20px;
        color: #333;
        font-weight: 900;
      }
      :nth-child(2){
        font-size: 16px;
        color:#333;
      }
    }

    .el-form-item{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
         .el-form-item__label{
            width: 82px !important;
            flex-shrink: 0;
            font-size: 14px;
            color: #333;
          }
          .el-input__inner{
            width: 540px;
          }
      }
      .protocol{
        display: flex;
        margin-bottom: 30px;
        margin-left: 79px;
        .protocol-word{
          color: #d82229;
        }
      }
      .el-checkbox__input.is-checked + .el-checkbox__label{
        color:#999;
      }
      .is-disabled{
        width: 100%;
        height:100%;
      }
      .el-checkbox{
        color: #999;
      }
      .save{
        display: flex;

        .el-form-item__content{
          width: 540px;
          height: 45px;
          color: #fff;
          font-size: 16px;
          background: #d82229;
          // margin: 0 auto;
          border-radius: 4px;
          margin-left: auto;
          cursor: pointer;
        }
      }
  }
  .is-disabled{
      background: #d82229;
      border: none;
      opacity: .5;
  }
  // .el-button--primary{
  //   width: 100%;
  //   height:100%;
  //   background: #d82229;
  //   border:none;
  //   // color: #fff;
  // }
}







 .section1{
         width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .el-tabs__item.is-active {
            color: #d82229;
          }

          .el-tabs__active-bar {
            height: 4px;
            background-color: #d82229;
          }

          .el-tabs__nav > div {
            &:hover {
              color: #d82229 !important;
            }
          }

          .el-tabs__item {
            font-size: 18px;
            color: #333;


          }

          .el-tabs__nav {
            height: 60px;
            line-height: 60px;
            margin-top: 5px;
          }

          .el-tabs__content {
            display: block;
          }
          .is-top{
            margin-bottom: 0px;
          }


          .recommend-list-wrap{
            margin-top: 15px;
            .recommend-list{ //大列表
               padding: 0 20px;
               height: 320px;
               background: #fff;
               margin-bottom: 20px;
               .shopAd{ //广告公司信息
                  height: 110px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  cursor: pointer;
                  .shopAd-content{ //左侧主体
                      width: 720px;
                      height: 70px;
                      display: flex;
                      justify-content: flex-start;
                      .shopAd-content-left{
                        margin-right: 15px;
                        img{
                          display: block;
                          width:70px;
                          height: 70px;
                          margin-right:10px;
                        }
                      }

                      .shopAd-content-right{

                        .opens{
                          height: 30px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          span{
                            margin-right: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                          }
                          :nth-child(1){
                            font-size: 18px;
                            color: #333;
                            font-weight: 900;
                          }
                          :nth-child(2){
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            line-height: 20px;
                            font-size: 14px;
                            color: #fff;
                            background: #ffac00;
                            border-radius: 2px;
                            // padding:2px;
                          }
                          :nth-child(5){
                            margin-right:6px;
                            font-size: 14px;
                            color: #666;
                            margin-right: 0;
                          }
                          :nth-child(6){
                            font-size: 14px;
                            margin: 0 10px;
                            color: #666;
                          }
                          .opens-adress{
                            display: block;
                             max-width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          }
                          :nth-child(8),:nth-child(9),:nth-child(10){
                            flex-shrink: 0;
                            font-size: 14px;
                            color: #666;
                            margin-right: 12px;
                          }

                        }
                        .server{
                          margin-top:10px;
                          height: 30px;
                          // background: orange;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          .server-title{
                              font-size: 14px;
                              color: #666;
                              margin-right: 12px;
                          }
                          .server-list{
                              font-size: 12px;
                              color:#999;
                              span{
                                padding: 5px 6px;
                                margin-right: 14px;
                                border: 1px solid #e5e5e5;
                                border-radius: 2px;
                              }
                              :last-child{
                                margin-right: 0;
                              }
                          }
                        }

                      }
                      .shopAd-btn{ //右侧进入店铺按钮
                    outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 16px;
                    color:#ffa219;
                    border-radius: 3px;
                    background: none;
                    border: 1px solid #ffa219;
                    cursor: pointer;
                    margin-right: 35px;
                  }

                  }
                  .shopAd-btn{ //右侧进入店铺按钮
                    outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 14px;
                    color:#ffa219;
                    border-radius: 3px;
                    background: none;
                    border: 1px solid #ffa219;
                    cursor: pointer;
                    margin-right: 70px;
                  }
                  .productBtnWrap{
                  width: 150px;
                  height: 110px;
                  padding-left: 40px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  margin-right: 70px;
                  padding: 15px 0 15px 40px;
                  .shopAd-btn{ //右侧进入店铺按钮
                    margin-left: auto;
                    outline: none;
                    width: 110px;
                    height: 34px;
                    font-size: 16px;
                    color:#fff;
                    border-radius: 3px;
                    background: none;
                    cursor: pointer;
                    margin-right: 70px;
                    border:1px solid #d82229;
                    color: #d82229;
                    // border: none;
                  }
                  :nth-child(2){
                    background: #d82229;
                    color: #fff;
                  }
                }
              }
              .otherwrap{
                line-height: 50px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                color: #999;
                span{
                  &:hover{
                  color: #ff0a51;
                  cursor: pointer;
                }
                }
              }
            }
            .recommend-list2{
              height: 280px;
            }
            >:last-child{
              margin-bottom: 0;
            }

            .productWrap{
              .productWrap-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                .productWrap-top-content{
                  display: flex;
                  dl{
                    width: 288px;
                    height: 140px;
                    display: flex;
                    margin-right: 20px;
                    justify-content: space-between;
                    dt{
                      width: 140px;
                      height: 140px;
                      overflow: hidden;
                      flex-shrink: 0;
                      img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                      }
                    }
                    dd{
                      width: 139px;
                      height: 100px;
                      font-size: 16px;
                      color: #333;
                      flex-shrink: 0;
                      >:nth-child(1){
                        margin-top: 11px;
                        height: 44px;
                        text-align: left;
                        cursor: pointer;
                      }
                      >:nth-child(1),>:nth-child(2){
                        text-align: left;
                        line-height:22px;
                        color: #333;
                        font-size: 16px;
                        word-break: break-all;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                      >:nth-child(2){
                        line-height: 34px;
                          >:nth-child(1){
                            color: #ff0a50;
                            font-weight: 600;
                          }
                      }
                      >:nth-child(3){
                        width: 110px;
                        height: 34px;
                        outline: none;
                        background: none;
                        border-radius: 3px;
                        border: 1px solid #333;
                        text-align: center;
                        line-height: 34px;
                        color:#333;
                        font-size: 14px;
                        cursor: pointer;
                        margin-top: 3px;
                      }
                    }
                  }
                }
                .productBtnWrap{
                  width: 150px;
                  height: 110px;
                  padding-left: 40px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  margin-right: 70px;
                  .shopAd-btn{ //右侧进入店铺按钮
                    margin-left: auto;
                    outline: none;
                    width: 110px;
                    height: 34px;
                    font-size: 16px;
                    color:#fff;
                    border-radius: 3px;
                    background: none;
                    cursor: pointer;
                    margin-right: 70px;
                    border:1px solid #d82229;
                    color: #d82229;
                    // border: none;
                  }
                  :nth-child(2){
                    background: #d82229;
                    color: #fff;
                  }
                }
              }

              .productWrapBtn{
                outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 16px;
                    color:#ffa219;
                    border-radius: 3px;
                    background: none;
                    border: 1px solid #ffa219;
                    cursor: pointer;
                    margin-right: 70px;
              }
              .oneKeywrap{
                font-size: 18px;
                .oneKey{
                    color: #ffac00;
                    font-size: 18px;
                 }
              }

            }
          }
          >:nth-child(1){
            margin-top: 0;
          }
    }

    // 以下缺省页
    .shop-default,.product-default{
      height: 500px;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 16px;
      line-height: 32px;
      padding-top: 130px;
      .needWrap{
        >:nth-child(1){
            color: #ffa219;
          }
          >:nth-child(3){
            color:#d82229;
            cursor: pointer;
          }
      }
     >:nth-child(1){
        >:first-child{
        color: #ffa219;
        // font-size: 14px;
      }
      >:nth-child(2){
        color: #ffa219;
        // font-size: 14px;
      }
     }
    }

.pagtion{
  display: flex;
  justify-content: center;
  margin-top:50px;
}

</style>
